<template>
  <v-card height="150px" class="noprint">
    <v-footer
      v-bind="localAttrs"
      :padless="padless"
    >
      <v-card
        flat
        tile
        width="100%"
        color="#963234"
        class="text-center"
      >
        <v-card-text>
          <v-btn
            v-for="icon in icons" :key="icon.link" class="mx-4" icon :href="icon.link || null"
            @click="icon.action || null">
           <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon size="24px" v-bind="attrs" v-on="on">
                    {{ icon.icon }}
                    </v-icon>
                </template>
            {{ icon.tooltip }}
           </v-tooltip>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          &copy; 2019 - {{ new Date().getFullYear() }} — MYSCH<br>by <a href="https://edvolute.com" class="footer-link">Edvolute Technology Limited</a> | <a href="https://wa.me/2347081307643" class="footer-link">Chat on WhatsAPP</a>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default {
    name: 'AppFooter',
    data: () => ({
        contactForm: false,
        icons: [
            {icon:'mdi-home', link: '/', tooltip: 'Home'},
            {icon:'mdi-message-text', link: 'mailto:support@mysch.ng', tooltip: 'Send us a Message'},
            {icon:'mdi-video-vintage', link: 'https://www.youtube.com/channel/UCAzLZ1dm9nE7b5H1IOmeyOw', tooltip: 'Watch how to use'},
        ],
        padless: true,
        variant: 'absolute',
    }),
    computed: {
      localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = true
        }
        return attrs
      },
    },
}
</script>

<style scoped>
.footer-link{
    color: #ebfdb8;
}
</style>