<template>
    <v-card elevation="1" class="card-chart" height="200">
      <v-card-title :class="color">
        <h4><v-icon class="white--text">{{titleicon}}</v-icon> {{title}}</h4>
      </v-card-title>
        <v-card-text class="card-body mt-1">
            {{description}} <small v-if="active"> -- version: <strong>{{version}}</strong></small>
        </v-card-text>
        <v-card-actions class="card-footer">
            <v-chip :color="active ? 'success' : 'yellow accent-1'" >
                <a v-if="active" class="mr-2 white--text" :href="link" target="_blank">Go to PORTAL » <v-icon>mdi-checkbox-marked</v-icon></a> 
                <span v-else><strong>Coming Soon!</strong></span>
            </v-chip>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'ModuleCard',
    props: [
        'title',
        'titleicon',
        'description',
        'link',
        'active',
        'version',
        'color'
    ]
}
</script>

<style>
a{text-decoration: none;}
</style>