<template>
    <v-container>
        <slider-card :carousels="carousels"/>
        <v-row class="mt-2">
            <v-col cols="12">
                <h2>Explore our Modules</h2>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-for="(mod, i) in modules" :key="i">
                <module-card :active="mod.active" :title="mod.title" :titleicon="mod.icon" :link="mod.link" :description="mod.description" :version="mod.version" :color="mod.color" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ModuleCard from '../components/ModuleCard.vue'
import SliderCard from '../components/SliderCard.vue'

  export default {
    name: 'Home',
    components: {ModuleCard, SliderCard},
    data: () => ({
        modules: [
            {active: true, title: 'Results Management', icon:'mdi-file-chart', link: 'https://results.mysch.ng', description: 'Create beautiful result report sheets & print them for your students & their Parents. Click the Go to Portal Link', version: '4.0.0', color: 'primary white--text'},
            {active: true, title: 'Teacher Lesson Plan', icon:'mdi-notebook-edit', link: 'https://teachplan.mysch.ng', description: 'Generate Lesson Plans and Notes in Seconds as a teacher, and Print them. Click the Go to Portal Link', version: '1.0.0', color: 'primary white--text'},
            {active: false, title: 'Flash Cards Learning', icon:'mdi-cards', link: '#', description: 'Teach kids using a wealth of knowledge using our Flash cards for different learning topics', version: '1.0.0', color: 'secondary yellow--text'},
            {active: false, title: 'Computer Based Testing', icon:'mdi-laptop', link: '#', description: 'Prepare & Test your students for exams and assessments online', version: '1.0.0', color: 'secondary yellow--text'},
            {active: false, title: 'E-Library & Books Module', icon:'mdi-book-open-variant', link: '#', description: 'Create & manage a Digital Library for your students and teachers to study', version: '1.0.0', color: 'secondary yellow--text'},
            {active: false, title: 'School Messaging Module', icon:'mdi-message-processing', link: '#', description: 'Send Customizable Bulk SMS to your teachers, staff, parents from one dashboard. ', version: '1.0.0', color: 'secondary yellow--text'},
        ],
        carousels: [
            {src: 'school.png'},
            {src: 'school2.png'},
            {src: 'school3.png'},
        ]
    }),
  }
</script>
