<template>
    <v-row>
        <v-col cols="12" md="5">
            <h2 class="text-h4 mt-10 font-weight-bold red--text darken-4">Your Awesome School Manager</h2>
            <p>Manage your School, Student Results, Testimonials, Certificates, Attendance, Learning etc all in One Place</p>
            <p>
                <a class="v-btn" href="https://wa.me/2347081307643">Whatsapp</a>
            </p>
        </v-col>
        <v-col cols="12" md="7">
            <v-carousel :show-arrows="false" height="200" cycle hide-delimiters>
                <v-carousel-item
                v-for="(item,i) in carousels"
                :key="i"
                :src="item.src"
                ></v-carousel-item>
            </v-carousel>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'SliderCard',
    props: [
        'carousels'
    ]
}
</script>

<style>
a{text-decoration: none;}
</style>