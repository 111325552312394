<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="MYSCH Logo"
          class="shrink mr-2"
          contain
          src="logo.png"
          transition="scale-transition"
          width="40"
        />
        <span class="red--text font-weight-bold">MYSCH.NG</span>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
      <app-footer/>
    </v-main>
  </v-app>
</template>

<script>
import AppFooter from './components/AppFooter.vue';

export default {
  components: { AppFooter },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
